* {
  margin: 0;
  font-family: "Poppins", Sans-serif;
}

html {
  scroll-snap-type: mandatory y;
  scroll-behavior: smooth;
}
section {
  scroll-snap-align: center;
}
body {
  padding-top: "70px";
}

.carousel-container {
  position: relative;
  border-radius: 16px;
  background-color: white;
  height: 500px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: auto 20px;
  cursor: grab;
}

.container:active {
  cursor: grabbing;
}

.slides-container {
  display: flex;
  justify-content: center;
}

.slide {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  user-select: none;
}

.slide img {
  position: relative;
  pointer-events: none;
  width: 200px;
  height: 300px;
  overflow: hidden;
  border-radius: 20px;
  object-fit: cover;
  transform-origin: bottom;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  will-change: transform;
}

.slide.active img {
  border: 10px solid cadetblue;
  transform: scale(1.2);
}
